export const projects = [
  {
    id: "Il-Monte-Galala",
    name: "Il Monte-Galala",
    mainImagePath: "/img/Il-Monte-Galala/6.jpg",
    tatweer: true,
    additionalImagePath: "/img/Il-Monte-Galala/2.jpg",
    projectInfo:
      "Rise to magnificent heights and discover a coastal universe of mountain-top exclusivity, radiating sunny adventures wherever the sky meets the sea. Grand and evidently generous, this global universe is a natural phenomena of unrivaled beauty, offering private floating landscapes at peak. Here, residential communities and freedom come alive on different heights, so you can simply elevate and immerse in beachfront simplicity combined with the highest degree of luxury — a place to curate heartfelt memories and deepened connections, above and below one of the world’s most.",
    developerInfo: `Since its inception in 2014, Tatweer Misr has been a vital catalyst for change, delivering incomparable value through exemplary projects that master all facets of development.

        Boasting a wealth of industrial and technical expertise, Tatweer Misr has been offering an innovative outlook on integrated living to strongly emerge as a leading real-estate developer in Egypt, fulfilling the rising demand on mixed-use projects that enrich the life of its communities.`,
    developerImagePath: "/img/part2.png",
    developer: true,
    location: "Il Monte-Galala",

    images: [
      {
        image: "/img/Il-Monte-Galala/1.jpg",
      },
      {
        image: "/img/Il-Monte-Galala/7.jpg",
      },
      {
        image: "/img/Il-Monte-Galala/3.jpg",
      },
      {
        image: "/img/Il-Monte-Galala/4.jpg",
      },
      {
        image: "/img/Il-Monte-Galala/5.jpg",
      },
      {
        image: "/img/Il-Monte-Galala/6.jpg",
      },
    ],
  },
  {
    id: "New-Giza",
    name: "New Giza",
    mainImagePath: "/img/New-Giza/6.jpg",
    tatweer: false,
    additionalImagePath: "/img/New-Giza/2.jpg",
    projectInfo:
      "Located at 22km on the Cairo-Alexandria Desert Road, the toll station is only 7km away. The 6th of October main road provides instant access. Meanwhile the Mehwar is 2km away. NEWGIZA is only 6km from the Ring Road connecting residents to the entirety of Cairo. Egypt’s new Grand Museum is also 6km away.",
    developerInfo: `Since its inception in 2014, Tatweer Misr has been a vital catalyst for change, delivering incomparable value through exemplary projects that master all facets of development.`,
    developerImagePath: "/img/newgiza.png",
    developer: true,
    location: "New Giza",
    images: [
      {
        image: "/img/New-Giza/1.jpg",
      },
      {
        image: "/img/New-Giza/13.jpg",
      },
      {
        image: "/img/New-Giza/12.jpg",
      },
      {
        image: "/img/New-Giza/4.jpg",
      },
      {
        image: "/img/New-Giza/5.jpg",
      },
      {
        image: "/img/New-Giza/6.jpg",
      },
    ],
  },
  {
    id: "Fouka-Bay",
    name: "Fouka Bay",
    mainImagePath: "/img/Fouka-Bay/6.jpg",
    tatweer: true,
    additionalImagePath: "/img/Fouka-Bay/2.jpg",
    projectInfo:
      "Wake up to a joyous mosaic of 12 island clusters, beckoning paradise-surroundings; fringed by 7 km of white sandy beaches, and soaked in Crystal Lagoons® all around. Expertly conceived in partnership with world-renowned Italian architects Gianluca Peluffo and Partners, Fouka Bay is the place to socialise, relax and repose, as you experience a unique profusion of tropical island life in the North Coast. Fouka Bay brings to fruition a special blend of beachfront simplicity, and true exclusivity, so that you can enjoy a front-row seat onto Ras El Hekma’s virgin coastline. Awarded 2018 Top Mediterranean Resort in Development by The Mediterranean Resort & Hotel Real Estate Forum, Fouka Bay was crowned  as an outstanding mixed -use resort showcasing dedication, innovation and expertise in tourism and hospitality across the Mediterranean region. Every home at Fouka Bay is crafted around your relaxation, and enjoyment. The development’s latest addition; The House Hotel & Residence is an exclusive invitation to enjoy the luxury of living in a beachfront serviced apartment while enjoying exciting water-sport activities, world-class dining experiences, and year- round entertainment with your loved ones.",
    developerInfo: `Since its inception in 2014, Tatweer Misr has been a vital catalyst for change, delivering incomparable value through exemplary projects that master all facets of development.

        Boasting a wealth of industrial and technical expertise, Tatweer Misr has been offering an innovative outlook on integrated living to strongly emerge as a leading real-estate developer in Egypt, fulfilling the rising demand on mixed-use projects that enrich the life of its communities.`,
    developerImagePath: "/img/part2.png",
    developer: true,
    location: "Fouka Bay",

    images: [
      {
        image: "/img/Fouka-Bay/1.jpg",
      },
      {
        image: "/img/Fouka-Bay/8.jpg",
      },
      {
        image: "/img/Fouka-Bay/3.jpg",
      },
      {
        image: "/img/Fouka-Bay/4.jpg",
      },
      {
        image: "/img/Fouka-Bay/5.jpg",
      },
      {
        image: "/img/Fouka-Bay/6.jpg",
      },
    ],
  },
  {
    id: "Porto-New-Cairo-Mall",
    name: "Porto New Cairo Mall",
    mainImagePath: "/img/Porto-New-Cairo-Mall/6.jpg",
    tatweer: true,
    additionalImagePath: "/img/Porto-New-Cairo-Mall/2.jpg",
    projectInfo:
      "Porto New Cairo Mall is a new retail destination in the heart of New Cairo, offering a unique shopping experience with a wide range of international brands and a variety of dining options. The mall is located in the heart of New Cairo, in the Porto New Cairo community, which is a mixed-use development that includes residential, commercial, and recreational facilities. The mall is located in the heart of New Cairo, in the Porto New Cairo community, which is a mixed-use development that includes residential, commercial, and recreational facilities.",
    developerInfo: `Since its inception in 2014, Tatweer Misr has been a vital catalyst for change, delivering incomparable value through exemplary projects that master all facets of development.

        Boasting a wealth of industrial and technical expertise, Tatweer Misr has been offering an innovative outlook on integrated living to strongly emerge as a leading real-estate developer in Egypt, fulfilling the rising demand on mixed-use projects that enrich the life of its communities.`,
    developerImagePath: "/img/part2.png",
    developer: false,
    location: "Porto New Cairo Mall",
    images: [
      {
        image: "/img/Porto-New-Cairo-Mall/1.jpg",
      },
      {
        image: "/img/Porto-New-Cairo-Mall/2.jpg",
      },
      {
        image: "/img/Porto-New-Cairo-Mall/3.jpg",
      },
      {
        image: "/img/Porto-New-Cairo-Mall/4.jpg",
      },
      {
        image: "/img/Porto-New-Cairo-Mall/5.jpg",
      },
      {
        image: "/img/Porto-New-Cairo-Mall/6.jpg",
      },
    ],
  },
  {
    id: "Sea-Shell",
    name: "Sea Shell",
    mainImagePath: "/img/Sea-Shell/10.jpg",
    tatweer: false,
    additionalImagePath: "/img/Sea-Shell/2.jpg",
    projectInfo:
      "Choose from an array of modern architectural design luxury villas and chalets created by Alchemy Design Studio. Home to ritzy boutiques, lush dining options and a boutique hotel, The G, presents luxury living on Egypt's North Coast catering to needs of a cosmopolitan crowd. ",
    developer: false,
    location: "Sea Shell",

    images: [
      {
        image: "/img/Sea-Shell/1.jpg",
      },
      {
        image: "/img/Sea-Shell/2.jpg",
      },
      {
        image: "/img/Sea-Shell/3.jpg",
      },
      {
        image: "/img/Sea-Shell/4.jpg",
      },
      {
        image: "/img/Sea-Shell/5.jpg",
      },
      {
        image: "/img/Sea-Shell/10.jpg",
      },
    ],
  },
  {
    id: "Sodic",
    name: "Sodic",
    mainImagePath: "/img/Sodic/6.jpg",
    location: "Sodic-West Sheikh Zayed",
    tatweer: false,
    additionalImagePath: "/img/Sodic/2.jpg",
    projectInfo:
      "Building a history of more than two decades of successful operation in Egypt, SODIC is one of the country’s leading real estate evelopment companies. Headquartered in Cairo and listed on the Egyptian stock exchange, SODIC brings to the market award winning large-scale developments, meeting Egypt’s ever-growing need for high quality housing, commercial and retail spaces. We pride ourselves on our passion for excellence and commitment to fostering long-term relationships with our clients, shareholders, business partners and employees, which has helped us grow to the corporation we are today.",
    developer: false,

    images: [
      {
        image: "/img/Sodic/1.jpg",
      },
      {
        image: "/img/Sodic/2.jpg",
      },
      {
        image: "/img/Sodic/3.jpg",
      },
      {
        image: "/img/Sodic/4.jpg",
      },
      {
        image: "/img/Sodic/5.jpg",
      },
      {
        image: "/img/Sodic/6.jpg",
      },
    ],
  },
];

import React from "react";
import Heading_Style from "./../../Style/HeadingAbout.module.css";
import HomeStyle from "./../../Style/Home.module.css";
import Footer from "./../Footer/Footer";
import AnimatedButton from "./../AnimatedButton/AnimatedButton";
import ContactStyle from "./../../Style/Contact.module.css";
import { useEffect } from "react";
import { useState } from "react";
import AnimatedBtnStyle from "../../Style/AnimatedBtnStyle.module.css";
import { Alert, AlertTitle } from "@mui/material";
import axios from "axios";
import { Button } from "bootstrap";
export default function Contact() {
  const [user, setUser] = useState({
    name: "",
    email: "",
    phoneNo: "",
    company: "",
    message: "",
  });
  const [messageOfApi, setMessageOfApi] = useState(null);
  const [loading , setLoading] = useState(false)

  function getUser(e) {
    let inputValue = e.target.value;
    let property = e.target.id;
    let newUser = { ...user };
    newUser[property] = inputValue;
    setUser(newUser);
  }

  async function sendForm(e) {
    e.preventDefault();
    const { data } = await axios.post(
      "https://ubtc-mail.onrender.com/contactus",
      user
    );
    
        console.log(data);
    if(data.message === "Email sent" ){
      setMessageOfApi("Email sent" )
      setLoading(true)
    }
  }

  return (
    <>
      <section className="py-5">
        <div className="container py-5">
          <div>
            <div className="position-relative my-5">
              <img src="./img/UBTC.png" alt="ubtc" className="" />
              <h2 className={Heading_Style.title}>Contact Us</h2>
            </div>

            <div className="row">
              <div className="col-md-8">
                <form onSubmit={sendForm}>
                  <div className="row  p-4">
                    <div className="col-md-6 mb-5 ">
                      <input
                        onChange={getUser}
                        required
                        type="text"
                        id="name"
                        placeholder="What is your name? *"
                        className="form-control form-control-lg bg-transparent p-3  "
                      />
                    </div>

                    <div className="col-md-6 mb-5  ">
                      <input
                        onChange={getUser}
                        required
                        id="email"
                        type="email"
                        placeholder="What it your email? *"
                        className="form-control form-control-lg bg-transparent p-3 "
                      />
                    </div>

                    <div className="col-md-6 mb-5 ">
                      <input
                        onChange={getUser}
                        required
                        id="phoneNo"
                        type="text"
                        placeholder="What is your phone number?* "
                        className="form-control form-control-lg bg-transparent p-3"
                      />
                    </div>

                    <div className="col-md-6 mb-5  ">
                      <input
                        onChange={getUser}
                        required
                        id="company"
                        type="text"
                        placeholder="What is your company?*"
                        className="form-control form-control-lg bg-transparent p-3"
                      />
                    </div>

                    <div className="col-md-12  mb-5">
                      <textarea
                        required
                        onChange={getUser}
                        className="form-control form-control-lg bg-transparent "
                        id="message"
                        placeholder="Write your message here"
                        rows="3"
                      ></textarea>
                    </div>

                    <div className="col-md-8 ">
                      <input
                        className={
                          AnimatedBtnStyle.btn_header + " w-100 contactSubmit"
                        }
                        type="submit"
                        value="Send"
                      />

                    </div>
                  </div>
                </form>


                <div className="text-center mt-2 ">
                  {messageOfApi!= null ? <Alert severity="success">
                  <AlertTitle>{messageOfApi}</AlertTitle>
                  </Alert>  : ""}
                </div>
              </div>

              <div className="col-md-4">
                <ul>
                  <li className="mb-4 text-black-75">
                    <i
                      className={
                        "fa-solid fa-xl fa-phone me-4 " + ContactStyle.icon
                      }
                    ></i>{" "}
                    +2 233 0528 53
                  </li>

                  <li className="mb-4 text-black-75">
                    <i
                      className={
                        "fa-solid fa-envelope fa-xl me-4 " + ContactStyle.icon
                      }
                    ></i>
                    Info@ubtc.com.eg
                  </li>

                  <li className="mb-4 text-black-75">
                    <i
                      className={
                        "fa-solid fa-location-dot fa-xl me-4 " +
                        ContactStyle.icon
                      }
                    ></i>
                    26 July St. Building no. 197 B, El Agouza Cairo
                  </li>
                </ul>

                <div className="">
                  <iframe
                    className=""
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.1161529778196!2d31.209344514958474!3d30.06220488187585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458411f3b46d6bb%3A0x90a5955277c013e!2sUBTC!5e0!3m2!1sen!2seg!4v1675000051195!5m2!1sen!2seg"
                    width="400"
                    height="300"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

import React, { useState } from "react";
import Footer from "./../Footer/Footer";
import Heading_Style from "./../../Style/HeadingAbout.module.css";
import { useEffect } from "react";
import ProjectDetailsStyle from "./../../Style/ProjectDetails.module.css";
import { useParams } from "react-router-dom";
import { projects } from "../../constants/index";

export default function ProjectDetails() {
  const { path } = useParams();
  const [project, setProject] = useState({});
  const [img , setImg] = useState()
  useEffect(() => {
    setProject(projects.find((project) => project.id === path));
  }, [path]);


  useEffect(() => {
    setImg(project.mainImagePath)
  }, [project]);
  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  });
  return (
    <>
      <section className="pt-5">
        <div className="container p-5">
          <div className="position-relative my-5">
            <img src="/./img/UBTC.png" alt="ubtc" className="" />
            <h2 className={Heading_Style.title + " "}>
              {" "}
              {project.name}
            </h2>
          </div>

          <figure className=" mb-5">
            <img
              src={img}
              className="w-100 p-3 bg-white"
              alt="w-100"
            />
          </figure>
          <div className="row mb-5">
            {project.images?.map((image, index) => <>


            <div key={index} className="col-lg-2 col-md-4 " onClick={()=> setImg(image.image)}>
              <img src={image.image} alt="img" className="w-100  p-3"/>
            </div>
         
            </>)}
            </div>
          <div>
            <div className="row g-3 align-items-center py-5">
              {
                project.tatweer && (<div className="col-md-6">
                <img
                  src="/./img/tatweer.png"
                  alt="Tatweer"
                  className="img-fulid"
                />
              </div>)
              }

              <div className="col-md-6 text-start">
                <h4 className={ProjectDetailsStyle.tatweer_h4}>
                  <i
                    className={
                      ProjectDetailsStyle.red + " fa-solid fa-location-dot me-3"
                    }
                  ></i>
                  {project.location}
                </h4>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-8">
              <h2 className={ProjectDetailsStyle.part1_title + " mb-4"}>
                <span className={ProjectDetailsStyle.red}>Project</span>{" "}
                Information{" "}
              </h2>
              <p className={ProjectDetailsStyle.desc}>
               {
                  project.projectInfo
               }
              </p>
            </div>

            <div className="col-lg-4">
              <figure className=" text-center ">
                <img
                  src={project.additionalImagePath}
                  alt="img"
                  className={
                    "w-100 p-3 bg-white " + ProjectDetailsStyle.rotateImg
                  }
                />
              </figure>
            </div>
          </div>

          {
            project.developer && (<div className="row mt-5">
            <div className="col-lg-4">
              <figure className=" text-center ">
                <img
                  src={project.developerImagePath}
                  alt="img"
                  className={
                    "w-100 p-3 bg-white " + ProjectDetailsStyle.rotateImg
                  }
                />
              </figure>
            </div>

            <div className="col-lg-8">
              <h2 className={ProjectDetailsStyle.part1_title + " mb-4"}>
                <span className={ProjectDetailsStyle.red}> About </span> the
                developer{" "}
              </h2>
              <p className={ProjectDetailsStyle.desc}>
                  {
                    project.developerInfo
                  }
              </p>
            </div>
          </div>)
          }
        </div>
      </section>

      <Footer />
    </>
  );
}

import React from "react";
import DashStyle from "./../../Style/Dash.module.css";

export default function Dash() {
  return (
    <>
      <section className={DashStyle.dash + " py-5"} >
        <div className="container">
          <div className="row g-3 justify-content-center" >
            <div className="col-md-2 d-flex justify-content-around align-items-center ">
              <div className=" text-center p-3 ">
                <img src="/./img/tatweer.svg" alt="img" className="w-100" />
              </div>
            </div>
            <div className="col-md-2 d-flex justify-content-around align-items-center">
              <div className=" text-center p-3 ">
                <img src="/./img/newgiza1.png" alt="img" className="w-100" />
              </div>
            </div>

            <div className="col-md-2 d-flex justify-content-around align-items-center">
              <div className=" text-center p-3 ">
                <img src="/./img/sea shell.png" alt="img" className="w-75" />
              </div>
            </div>

            <div className="col-md-2 d-flex justify-content-around align-items-center">
              <div className=" text-center p-3 ">
                <img src="/./img/sodic.png" alt="img" className="w-75" />
              </div>
            </div>
            <div className="col-md-2 d-flex justify-content-around align-items-center">
              <div className=" text-center p-3 ">
                <img src="/./img/fouka bay.png" alt="img" className="w-75" />
              </div>
            </div>
            <div className="col-md-2 d-flex justify-content-around align-items-center">
              <div className=" text-center p-3 ">
                <img src="/./img/trio gardens.png" alt="img" className="w-75" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

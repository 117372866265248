import React from "react";
import Footer from "./../Footer/Footer";
import CareerStyle from "./../../Style/Career.module.css";
import Heading_Style from "./../../Style/HeadingAbout.module.css";
import { useEffect, useRef, useState } from "react";
import AnimatedButton from "../AnimatedButton/AnimatedButton";
import emailjs from "@emailjs/browser";
import AnimatedBtnStyle from "../../Style/AnimatedBtnStyle.module.css";
import { Alert, AlertTitle } from "@mui/material";
import axios from "axios";
export default function Careers() {
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [phoneNo, setphoneNo] = useState("");
  const [department, setdepartment] = useState("Engineering");
  const [summary, setsummary] = useState("");
  const [file, setfile] = useState("");
  const [messageOfApi, setMessageOfApi] = useState(null);

  async function sendForm(e) {
    e.preventDefault();
    const formDate = new FormData();
    formDate.append("firstName", firstName);
    formDate.append("lastName", lastName);
    formDate.append("email", email);
    formDate.append("phoneNo", phoneNo);
    formDate.append("department", department);
    formDate.append("summary", summary);
    formDate.append("resume", file);
    console.log(firstName, lastName, email);

    const { data } = await axios.post(
      "https://ubtc-mail.onrender.com/contactus/career",
      formDate
    );
    console.log(data);
    if (data.message === "Email sent") {
      setMessageOfApi("Email sent");
    }
  }

  return (
    <>
      <section className="pt-5">
        <div className="container pt-5">
          <div className="position-relative py-5">
            <img src="./img/UBTC.png" alt="ubtc" className="" />
            <h2 className={Heading_Style.title}>Careers</h2>
          </div>

          <form onSubmit={sendForm}>
            <div className="row">
              <div className="col-md-6 pe-md-5">
                <label className="px-5" htmlFor="firstName">
                  First Name
                </label>
                <div className="px-5">
                  <input
                    onChange={(e) => setfirstName(e.target.value)}
                    required
                    id="firstName"
                    type="text"
                    className="form-control form-control-lg"
                  />
                </div>
              </div>

              <div className="col-md-6 ps-md-5">
                <label className="px-5" htmlFor="lastName">
                  Last Name
                </label>
                <div className="px-5">
                  <input
                    onChange={(e) => setlastName(e.target.value)}
                    required
                    id="lastName"
                    type="text"
                    className="form-control form-control-lg"
                  />
                </div>
              </div>

              <div className="col-md-12">
                <label htmlFor="email" className="mt-4 px-5">
                  Email
                </label>
                <div className="px-5">
                  <input
                    onChange={(e) => setemail(e.target.value)}
                    required
                    id="email"
                    type="email"
                    className="form-control form-control-lg"
                  />
                </div>
              </div>

              <div className="col-md-8 pe-md-5">
                <label className="px-5 mt-3" htmlFor="phoneNo">
                  Phone Number
                </label>
                <div className="px-5">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      +2
                    </span>
                    <input
                      onChange={(e) => setphoneNo(e.target.value)}
                      required
                      id="phoneNo"
                      type="text"
                      className="form-control form-control-lg"
                      aria-label="Username"
                      aria-describedby="basic-addon1 phone"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4 ps-md-5">
                <label className="px-5 mt-3" htmlFor="department">
                  Department applied for
                </label>
                <div className="px-5">
                  <select
                    required
                    id="department"
                    className="form-select form-control-lg"
                    aria-label="Default select example"
                    onChange={(e) => setdepartment(e.target.value)}
                  >
                    <option selected value="Engineering">
                      Engineering
                    </option>
                    <option value=" Construction assistant">
                      {" "}
                      Construction assistant
                    </option>
                    <option value="Equipment operator">
                      {" "}
                      Equipment operator
                    </option>
                    <option value="Inspector">Inspector</option>
                    <option value="General laborer"> General laborer</option>
                    <option value="Field engineer"> Field engineer</option>
                    <option value="Civil engineer"> Civil engineer</option>
                    <option value="senior Project manager">
                      {" "}
                      senior Project manager
                    </option>
                    <option value="Architect">Architect</option>
                    <option value="Structural engineer">
                      Structural engineer
                    </option>
                    <option value="Construction engineer">
                      Construction engineer
                    </option>
                    <option value="Safety engineer">Safety engineer</option>
                    <option value="Human Resource">Human Resource</option>
                    <option value="Finance">Finance</option>
                  </select>
                </div>
              </div>

              <div className="col-md-8">
                <label className="px-5 mt-3" htmlFor="summary">
                  Summary
                </label>
                <div className="px-5">
                  <textarea
                    onChange={(e) => setsummary(e.target.value)}
                    required
                    id="summary"
                    className="form-control form-control-lg p-5"
                  ></textarea>
                </div>
              </div>

              <div className="col-md-8">
                <label className="px-5 mt-3" htmlFor="resume">
                  Resume
                </label>
                <div className="px-5">
                  <input
                    onChange={(e) => setfile(e.target.files[0])}
                    id="resume"
                    required
                    type="file"
                  />
                </div>
              </div>

              <div className="col-md-8 mt-5 text-center">
                <input
                  className={
                    AnimatedBtnStyle.btn_header + " w-100 contactSubmit"
                  }
                  type="submit"
                  value="Send"
                />
              </div>
            </div>
          </form>

          <div className="text-center mt-3 my-4">
                  {messageOfApi!= null ? <Alert severity="success">
                  <AlertTitle>{messageOfApi}</AlertTitle>
                  </Alert>  : ""}
                </div>
              </div>

        <Footer />
      </section>
    </>
  );
}

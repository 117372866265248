import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Componants/Home/Home";
import Main from "./Componants/Main/Main";
import About from "./Componants/About/About";
import Service from "./Componants/Service/Service";
import Project from "./Componants/Project/Project";
import Blog from "./Componants/Blog/Blog";
import Contact from "./Componants/Contact/Contact";
import ServiceDetails from "./Componants/ServiceDetails/ServiceDetails";
import BlogInfo from "./Componants/BlogInfo/BlogInfo";
import AllProject from "./Componants/AllProject/AllProject";
import ProjectDetails from "./Componants/ProjectDetails/ProjectDetails";
import Careers from "./Componants/Careers/Careers";
// import Pag from './Componants/Pag';
import BlogDetalis from "./Componants/BlogDetails/BlogDetalis";
import Maintenance from "./Componants/Maintenance/Maintenance";

export default function App() {
  const router = createBrowserRouter([
    {
      path: "",
      element: <Main />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/about", element: <About /> },
        { path: "/project", element: <AllProject /> },
        // { path: "/blog", element: <BlogInfo /> },
        { path: "/contactUs", element: <Contact /> },
        { path: "/career", element: <Careers /> },

        // {
        //   path: "/service-details",
        //   element: <ServiceDetails />,
        //   children: [{}],
        // },

        {
          path: "/project-details/:path",
          element: <ProjectDetails />,
          children: [
            {
              path: "",
            },
          ],
        },

        // { path: "/blog-details", element: <BlogDetalis />, children: [{}] },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

import Aos from "aos";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AnimatedButton from "../AnimatedButton/AnimatedButton";
import Dash from "../Dash/Dash";
import FooterStyle from "./../../Style/Footer.module.css";
import HomeStyle from "./../../Style/Home.module.css";
import { NavHashLink } from 'react-router-hash-link';


export default function Footer() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  let path = useLocation().pathname

  useEffect(() => {
  }, [path])

  return (
    <>
      <footer className="" id={path === '/' ? "contact" : null} >
        <Dash />
        <div className="container-fluid"
          style={{
            background: "#131B23",
          }}
        >
          <div className="row">
            <div className={FooterStyle.red + " col-md-5 "}>
              <div className="item p-lg-5">
                <div className="pt-5">
                  <div className="p-5 text-white">
                    {/* <h2 className={FooterStyle.item_title + " mb-4"}>UBTC</h2> */}
                    <div className="mb-5">
                      <img src="/./img/footer.png" alt="" className="ubtcLogo" />
                    </div>
                    <span className={FooterStyle.call + " "}>CALL US TODAY</span>
                    <h4 className={FooterStyle.phone + " my-3"}>
                      +2 233 0528 53
                    </h4>

                    <div className=" d-flex d-flex justify-content-start align-items-center ">
                      <span
                        className={
                          FooterStyle.icone +
                          " rounded-circle ms-3  d-flex d-flex justify-content-center align-items-center "
                        }
                      >
                        <a href="https://www.facebook.com/UBTC.COM.EG/" target="_blank">
                          <i className="fa-brands text-black fa-xl  fa-facebook-f"></i>
                        </a>
                      </span>
                      <span
                        className={
                          FooterStyle.icone +
                          " rounded-circle ms-3  d-flex d-flex justify-content-center align-items-center "
                        }
                      >
                        <a href="https://www.linkedin.com/company/united-brothers-for-trading-and-construction-%E2%80%9Cubtc%E2%80%9D/" target="_blank">
                          <i className="fa-brands text-black fa-xl  fa-linkedin"></i>
                        </a>
                      </span>
                    </div>



                  </div>
                </div>
              </div>
            </div>

            <div className={FooterStyle.black + " col-md-7   px-3"}>
              <div className="py-lg-5">
                <div className="row">
                  <div className="col-md-12 ps-5">
                    <div className="">

                      <div className=" py-5 " >
                        <AnimatedButton
                          title={"Contact US"}
                          link="/contactUs"
                          fullWidth={true}
                        />


                      </div>
                      <h3
                        className={FooterStyle.foot_title + " mb-5 text-white "}
                      >
                        {" "}
                        Quick Links
                      </h3>
                      <NavHashLink spy={true} offset={-90} duration={500} to="/#home">
                        <div className="mb-3">Home</div>
                      </NavHashLink>

                      <Link to="/about">
                        <div className="mb-3">About Company</div>
                      </Link>



                      <NavHashLink spy={true} offset={-90} duration={500} to="/#services">
                        <div className="mb-3">Our Services</div>
                      </NavHashLink>

                      <Link to="/project">
                        <div className="mb-3">Our Projects</div>
                      </Link>

                      <Link to="/contactUs">
                        <div className="mb-5">Contacts Us</div>
                      </Link>

                      <div className={FooterStyle.foot + " pb-5 text-white"}>
                        <a href="https://abnuur.net" target="_blank" className="me-3">
                          <span>
                            <img src="/./img/abnuurFoot.png" alt="abnour" className="img-abnor" />
                          </span>
                        </a>
                      </div>

                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

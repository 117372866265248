import React, {useState , useEffect } from "react";
import Heading_Style from "./../../Style/HeadingAbout.module.css";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useLocation } from 'react-router-dom';
import AnimatedButton from "../AnimatedButton/AnimatedButton";

export default function HeadingAbout() {

  const [counterExp, setCounterExp] = useState(0);
  const [counterProject, setCounterProject] = useState(0);
  const [counterEmployee, setCounterEmployee] = useState(0);

  let path = useLocation().pathname

  useEffect(() => {
  }, [path])



  useEffect(() => {

    if(counterExp != 13){
      var intervalCount = setInterval(() => {

        setCounterExp((prevCounter) => prevCounter + 1);

    }, 50);
    }


    return () => clearInterval(intervalCount);
  }, [counterExp]);



  useEffect(() => {

    if(counterProject != 12){
      var intervalProject = setInterval(() => {

        setCounterProject((prevCounter) => prevCounter + 1);

    }, 50);
    }


    return () => clearInterval(intervalProject);
  }, [counterProject]);



  useEffect(() => {

    if(counterEmployee != 300){
      var intervalEmplyee = setInterval(() => {

        setCounterEmployee((prevCounter) => prevCounter + 1);

    }, 5);
    }


    return () => clearInterval(intervalEmplyee);
  }, [counterEmployee]);


  return (
    <>
      <section className="py-5 mt-3" id="about">
        <div className="container">
          <div className="row g-3 mb-5">
            <div className="col-md-6">
              <div>
                <div className="position-relative my-5">
                  <img src="./img/UBTC.png" alt="ubtc" className="w-50" />
                  <h2 className={Heading_Style.title}>About us</h2>
                </div>

                <h3 className={Heading_Style.header + " my-4"}>
                  United Brothers for <br/> Trading and Construction
                </h3>

                <h2 className={Heading_Style.heading + " my-3"}>
                  one of the most upgrading and promising companies in Egypt.{" "}
                </h2>
                {path =="/"?<div className="mb-3 pb-5">
                <p className={Heading_Style.abut_desc + " mb-4 mt-4"}>
                UBTC Construction is a well-established construction company that has been 
operating in the Egyptian market for over 13 years. They specialize in both commercial 
and residential construction services, providing quality work and excellent customer 
service. Their experienced team is able to provide custom solutions for all types of 
projects, from large-scale developments ........
                </p>
                </div>:<p className={Heading_Style.abut_desc}>
UBTC Construction is a well-established construction company that has been 
operating in the Egyptian market for over 13 years. They specialize in both commercial 
and residential construction services, providing quality work and excellent customer 
service. Their experienced team is able to provide custom solutions for all types of 
projects, from large-scale developments to simple renovations. UBTC Construction is 
dedicated to safety and always follows the highest standards for safety and quality in 
the industry. They are committed to providing the best possible experience for their 
customers and ensuring the highest level of satisfaction. With their years of 
experience and professional service, UBTC Construction has become a trusted name in 
the Egyptian construction market.
</p>

}
                <div className="row">
                  <div className="col-md-8 col-sm-12">
                  {path == "/"? <AnimatedButton title={"Read More"} link="/about" fullWidth={true} />: ""}
                  </div>
                </div>
                

              </div>
            </div>

            <div className="col-md-6  mb-5 p-5  text-end ">
              <div className=" position-relative pt-5 ">
                <figure className="text-end ">
                  <img
                    src="./img/about1.png "
                    alt="img"
                    className={Heading_Style.photo +" bg-white p-3"}
                  />
                </figure>

                <figure className={Heading_Style.img_layer}>
                  <img
                    src="./img/about2.png"
                    alt="img"
                    className="bg-white p-3"
                  />
                </figure>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="dashbord p-5 text-center ">
                <h4 className={Heading_Style.dashbord_title}>{counterExp}+</h4>
                <h3 className={Heading_Style.dashbord_desc}>
                  Years of Experience
                </h3>
              </div>
            </div>

            <div className="col-md-4">
              <div className="dashbord p-5 text-center">
                <h4 className={Heading_Style.dashbord_title}>{counterProject}+</h4>
                <h3 className={Heading_Style.dashbord_desc}>Projects Done</h3>
              </div>
            </div>

            <div className="col-md-4">
              <div className="dashbord p-5 text-center">
                <h4 className={Heading_Style.dashbord_title}>{counterEmployee}+</h4>
                <h3 className={Heading_Style.dashbord_desc}>Total Employees</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

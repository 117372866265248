import React from "react";
import { Link } from "react-router-dom";
import styles from "../../Style/BlogCard.module.css";

const BlogCard = ({ item, serviceId }) => {
  return (
    <div className={styles.BlogCard} style={{ height: "350px" ,backgroundColor:'#131B23'}}>
      <Link>
        <img src={item.imgPath} alt="" className="w-100" />
        <div className={styles.text}>
          <h2
            className={styles.text__bottom}
            style={{ color: "#fff", fontWeight: "bold" }}
          >
            {item.projectTitle}
          </h2>
        </div>
      </Link>
    </div>
  );
};

export default BlogCard;

import React from "react";
import Heading_Style from "./../../Style/HeadingAbout.module.css";
import Footer from "./../Footer/Footer";
import { useEffect } from "react";
import Pagination from "./Pagination";

export default function AllProject() {
  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  });

  const projectsArray = [
    {
      id: "1",
      projectTitle: "New Giza for Real Estate Development",
      projectDescribtion:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, fuga et aspernatur, autem ex animi nesciunt quam necessitatibus cumque itaque, earum laudantium veritatis accusamus! Voluptatum ducimus qui repellat ratione eaque",
      Field: "Constructions",
      CreatedAt: "Feb-2-2023",
      createdBy: "Hoda Khaled",
      imgPath: "./img/New-Giza/5.jpg",
    },
    {
      id: "2",
      projectTitle: "IL Monte Galala",
      projectDescribtion:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, fuga et aspernatur, autem ex animi nesciunt quam necessitatibus cumque itaque, earum laudantium veritatis accusamus! Voluptatum ducimus qui repellat ratione eaque",
      Field: "Constructions",
      CreatedAt: "Feb-2-2023",
      createdBy: "Hoda Khaled",
      imgPath: "./img/Il-Monte-Galala/9.jpg",
    },
    {
      id: "3",
      projectTitle: "Emerald Park",
      projectDescribtion:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, fuga et aspernatur, autem ex animi nesciunt quam necessitatibus cumque itaque, earum laudantium veritatis accusamus! Voluptatum ducimus qui repellat ratione eaque",
      Field: "Constructions",
      CreatedAt: "Feb-2-2023",
      createdBy: "Hoda Khaled",
      imgPath: "/img/Emerald-Park/1.jpg",
    },
    {
      id: "4",
      projectTitle: "Seashell",
      projectDescribtion:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, fuga et aspernatur, autem ex animi nesciunt quam necessitatibus cumque itaque, earum laudantium veritatis accusamus! Voluptatum ducimus qui repellat ratione eaque",
      Field: "Constructions",
      CreatedAt: "Feb-2-2023",
      createdBy: "Hoda Khaled",
      imgPath: "./img/Sea-Shell/1.jpg",
    },
    {
      id: "2",
      projectTitle: "Emerald mall",
      projectDescribtion:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, fuga et aspernatur, autem ex animi nesciunt quam necessitatibus cumque itaque, earum laudantium veritatis accusamus! Voluptatum ducimus qui repellat ratione eaque",
      Field: "Constructions",
      CreatedAt: "Feb-2-2023",
      createdBy: "Hoda Khaled",
      imgPath: "/img/Emerald-Mall/1.jpg",
    },
    {
      id: "3",
      projectTitle: "Bloom Fields",
      projectDescribtion:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, fuga et aspernatur, autem ex animi nesciunt quam necessitatibus cumque itaque, earum laudantium veritatis accusamus! Voluptatum ducimus qui repellat ratione eaque",
      Field: "Constructions",
      CreatedAt: "Feb-2-2023",
      createdBy: "Hoda Khaled",
      imgPath: "/img/Bloom-Fields/1.jpg",
    },
    {
      id: "4",
      projectTitle: "Fouka Bay",
      projectDescribtion:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, fuga et aspernatur, autem ex animi nesciunt quam necessitatibus cumque itaque, earum laudantium veritatis accusamus! Voluptatum ducimus qui repellat ratione eaque",
      Field: "Constructions",
      CreatedAt: "Feb-2-2023",
      createdBy: "Hoda Khaled",
      imgPath: "./img/Fouka-Bay/4.jpg",
    },
    {
      id: "2",
      projectTitle: "Damac",
      projectDescribtion:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, fuga et aspernatur, autem ex animi nesciunt quam necessitatibus cumque itaque, earum laudantium veritatis accusamus! Voluptatum ducimus qui repellat ratione eaque",
      Field: "Constructions",
      CreatedAt: "Feb-2-2023",
      createdBy: "Hoda Khaled",
      imgPath: "/img/Damac/1.jpg",
    },
    {
      id: "3",
      projectTitle: "Dewood Factory",
      projectDescribtion:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, fuga et aspernatur, autem ex animi nesciunt quam necessitatibus cumque itaque, earum laudantium veritatis accusamus! Voluptatum ducimus qui repellat ratione eaque",
      Field: "Constructions",
      CreatedAt: "Feb-2-2023",
      createdBy: "Hoda Khaled",
      imgPath: "/img/Dewood-Factory/1.jpg",
    },
    {
      id: "4",
      projectTitle: "Porto New Cairo Mall",
      projectDescribtion:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, fuga et aspernatur, autem ex animi nesciunt quam necessitatibus cumque itaque, earum laudantium veritatis accusamus! Voluptatum ducimus qui repellat ratione eaque",
      Field: "Constructions",
      CreatedAt: "Feb-2-2023",
      createdBy: "Hoda Khaled",
      imgPath: "/img/Porto-New-Cairo-Mall/1.jpg",
    }
    
  ];
  return (
    <>
      <section className="pt-5">
        <div className="container">
          <div className="position-relative my-5">
            <img src="./img/UBTC.png" alt="ubtc" className="" />
            <h2 className={Heading_Style.title}>Projects</h2>
          </div>
          <Pagination items={projectsArray} itemsPerPage={10} />

          {/* pagination from api  */}
        </div>
      </section>

      <Footer />
    </>
  );
}

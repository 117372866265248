import React from "react";
import NavbarStayle from "./../../Style/Navbar.module.css";
import { Link } from "react-scroll";
import { NavHashLink } from "react-router-hash-link";
import "../../Style/Navbar.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';



export default function NavbarComponent() {
  let path = useLocation().pathname;

  useEffect(() => {}, [path]);



  return (
    <>
      {/* <nav className="navbar navbar-expand-lg py-2">
        <div className="container">
          <a href="/" className="navbar-brand">
            <img
              src="/./img/logo.svg"
              alt="logo"
              className="logo"
              style={{ cursor: "pointer" }}
            />
          </a>
          <button
            className="navbar-toggler border-0 outline-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img src="/./img/nav.png" alt="nav" />
          </button>
          <div
            className="collapse navbar-collapse "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item mt-2 me-4">
                {path == "/" ? (
                  <a
                    className={"nav-link  " + NavbarStayle.navbar_link}
                    href="/"
                    spy={true}
                    smooth={true}
                    offset={-90}
                    duration={500}
                  >
                    Home
                  </a>
                ) : (
                  <NavHashLink
                    className={"nav-link  " + NavbarStayle.navbar_link}
                    spy={true}
                    smooth={true}
                    offset={-90}
                    duration={500}
                    to="/#home"
                  >
                    Home
                  </NavHashLink>
                )}
              </li>

              <li className="nav-item mt-2  me-4">
                {path == "/" ? (
                  <Link
                    className={"nav-link  " + NavbarStayle.navbar_link}
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-90}
                    duration={500}
                  >
                    About Us
                  </Link>
                ) : (
                  <NavHashLink
                    className={"nav-link  " + NavbarStayle.navbar_link}
                    spy={true}
                    smooth={true}
                    offset={-90}
                    duration={500}
                    to="/#about"
                  >
                    About Us
                  </NavHashLink>
                )}
              </li>
              <li className="nav-item mt-2 me-4">
                {path == "/" ? (
                  <Link
                    className={"nav-link " + NavbarStayle.navbar_link}
                    to="services"
                    spy={true}
                    offset={-90}
                    duration={500}
                  >
                    Services
                  </Link>
                ) : (
                  <NavHashLink
                    className={"nav-link " + NavbarStayle.navbar_link}
                    to="/#services"
                    spy={true}
                    offset={-90}
                    duration={500}
                  >
                    Services
                  </NavHashLink>
                )}
              </li>

              <li className="nav-item mt-2 me-4">
                {path == "/" ? (
                  <Link
                    className={"nav-link " + NavbarStayle.navbar_link}
                    to="project"
                    spy={true}
                    offset={-90}
                    duration={500}
                  >
                    Projects
                  </Link>
                ) : (
                  <NavHashLink
                    className={"nav-link " + NavbarStayle.navbar_link}
                    to="/#project"
                    spy={true}
                    offset={-90}
                    duration={500}
                  >
                    Projects
                  </NavHashLink>
                )}
              </li>

              <li className="nav-item mt-2 me-4">
                {path == "/" ? (
                  <Link
                    className={"nav-link " + NavbarStayle.navbar_link}
                    to="contact"
                    spy={true}
                    offset={50}
                    duration={500}
                  >
                    Contact Us
                  </Link>
                ) : (
                  <NavHashLink
                    className={"nav-link " + NavbarStayle.navbar_link}
                    to="/contactUs"
                    spy={true}
                    offset={50}
                    duration={500}
                  >
                    Contact Us
                  </NavHashLink>
                )}
              </li>

              <li className="nav-item mt-2 me-4">
                <NavHashLink
                  className={`nav-link ${path === "/career" ? "active" : ""} ${
                    NavbarStayle.navbar_link
                  }`}
                  to="/career"
                >
                  Careers
                </NavHashLink>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}

<Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home">
        <a href="/" className="navbar-brand">
            <img
              src="/./img/logo.svg"
              alt="logo"
              className="logo"
              style={{ cursor: "pointer" }}
            />
          </a>
        </Navbar.Brand>
        <Navbar.Toggle className="border-0 outline-0" aria-controls="basic-navbar-nav" >
        <img src="/./img/nav.png" alt="nav" />

        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
              <li className="nav-item mt-2 me-4">
                {path == "/" ? (
                  <a
                    className={"nav-link  " + NavbarStayle.navbar_link}
                    href="/"
                    spy={true}
                    smooth={true}
                    offset={-90}
                    duration={500}
                  >
                    Home
                  </a>
                ) : (
                  <NavHashLink
                    className={"nav-link  " + NavbarStayle.navbar_link}
                    spy={true}
                    smooth={true}
                    offset={-90}
                    duration={500}
                    to="/#home"
                  >
                    Home
                  </NavHashLink>
                )}
              </li>

              <li className="nav-item mt-2  me-4">
                {path == "/" ? (
                  <Link
                    className={"nav-link  " + NavbarStayle.navbar_link}
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-90}
                    duration={500}
                  >
                    About Us
                  </Link>
                ) : (
                  <NavHashLink
                    className={"nav-link  " + NavbarStayle.navbar_link}
                    spy={true}
                    smooth={true}
                    offset={-90}
                    duration={500}
                    to="/#about"
                  >
                    About Us
                  </NavHashLink>
                )}
              </li>
              <li className="nav-item mt-2 me-4">
                {path == "/" ? (
                  <Link
                    className={"nav-link " + NavbarStayle.navbar_link}
                    to="services"
                    spy={true}
                    offset={-90}
                    duration={500}
                  >
                    Services
                  </Link>
                ) : (
                  <NavHashLink
                    className={"nav-link " + NavbarStayle.navbar_link}
                    to="/#services"
                    spy={true}
                    offset={-90}
                    duration={500}
                  >
                    Services
                  </NavHashLink>
                )}
              </li>

              <li className="nav-item mt-2 me-4">
                {path == "/" ? (
                  <Link
                    className={"nav-link " + NavbarStayle.navbar_link}
                    to="project"
                    spy={true}
                    offset={-90}
                    duration={500}
                  >
                    Projects
                  </Link>
                ) : (
                  <NavHashLink
                    className={"nav-link " + NavbarStayle.navbar_link}
                    to="/#project"
                    spy={true}
                    offset={-90}
                    duration={500}
                  >
                    Projects
                  </NavHashLink>
                )}
              </li>

              <li className="nav-item mt-2 me-4">
                {path == "/" ? (
                  <Link
                    className={"nav-link " + NavbarStayle.navbar_link}
                    to="contact"
                    spy={true}
                    offset={50}
                    duration={500}
                  >
                    Contact Us
                  </Link>
                ) : (
                  <NavHashLink
                    className={"nav-link " + NavbarStayle.navbar_link}
                    to="/contactUs"
                    spy={true}
                    offset={50}
                    duration={500}
                  >
                    Contact Us
                  </NavHashLink>
                )}
              </li>

              <li className="nav-item mt-2 me-4">
                <NavHashLink
                  className={`nav-link ${path === "/career" ? "active" : ""} ${
                    NavbarStayle.navbar_link
                  }`}
                  to="/career"
                >
                  Careers
                </NavHashLink>
              </li>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    </>
  );
}

import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Heading_Style from "./../../Style/HeadingAbout.module.css";
import styles from "../../Style/Project.module.css";
import serviceStyle from "./../../Style/service.module.css";
import { useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Aos from "aos";
import { useMediaQuery } from "@mui/material";
export default function Project() {
  let path = useLocation().pathname;
  
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);




  const breakPoint = useMediaQuery('(max-width: 1030px)')
  return (
    <>
      <section className={`${styles.project} `} id="project">
        <div className="container">
        <div className="row">
            <div className="col-sm-6">
            <div className="position-relative my-5">
            <img src="./img/ubtcgray.png" alt="ubtc" className="w-25" />
            <h2 className={Heading_Style.title + " text-white "} >All Project 
            <Link  className="text-white ms-5 seeMore  fa-beat-fade  text-decoration-underline" to="/project">SEE MORE  <i className="fa-solid fa-angles-right ms-2"></i></Link>
            </h2>
          </div>
            </div>

            {/* <div className="col-sm-6 d-flex justify-content-end align-items-center">
              <div className="text-center">

              </div>
            </div> */}
          </div>
          {/* {breakPoint ?
      <>
                            <div className="row ">
                      <div className="col-md-3 ">
                      <Link to={"/project-details/Il-Monte-Galala"}>
                          <figure className={styles.service_img}>
                            <img
                              src="./img/Il-Monte-Galala/9.jpg"
                              alt="project"
                              className="w-100"
                            />
                            <div
                              className={
                                styles.loyout +
                                " d-flex justify-content-center align-items-center text-center"
                              }
                            >
                              <div>
                                <h5 className="text-uppercase project_tit  mb-3">Project</h5>
                                <h3 className="my-3 project_desc_layout ">IL Monte Galala</h3>
        
                                <div
                                  className={
                                    styles.icon +
                                    " d-flex justify-content-center align-items-center text-center m-auto"
                                  }
                                >
                                  <i
                                    className={
                                      "fa-solid fa-plus fa-lg " + styles.plus
                                    }
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </figure>
                        </Link>
                      </div>
        
                      <div className="col-md-9 ">
                        <div className="row">
                          <div className="col-md-8">
                            <Link to="/">
                              <figure className={serviceStyle.service_img}>
                                <img
                                  src="./img/project/project2.png"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    serviceStyle.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">
                                      New Giza for Real Estate Development
                                    </h3>
        
                                    <div
                                      className={
                                        serviceStyle.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + serviceStyle.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
        
                          <div className="col-md-4">
                            <Link to="/">
                              <figure className={serviceStyle.service_img}>
                                <img
                                  src="./img/project/project3.png"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    serviceStyle.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">Fouka Bay</h3>
        
                                    <div
                                      className={
                                        serviceStyle.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + serviceStyle.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
        
                          <div className="col-md-4">
                            <Link to="/">
                              <figure className={serviceStyle.service_img}>
                                <img
                                  src="./img/project/project4.png"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    serviceStyle.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">Ein Bay</h3>
        
                                    <div
                                      className={
                                        serviceStyle.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + serviceStyle.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
        
                          <div className="col-md-4 ">
                            <Link to="/">
                              <figure className={serviceStyle.service_img}>
                                <img
                                  src="./img/project/project5.png"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    serviceStyle.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">Seashell</h3>
        
                                    <div
                                      className={
                                        serviceStyle.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + serviceStyle.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
        
                          <div className="col-md-4 ">
                            <Link to="/">
                              <figure className={serviceStyle.service_img}>
                                <img
                                  src="./img/project/project6.png"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    serviceStyle.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">Fouka Bay</h3>
        
                                    <div
                                      className={
                                        serviceStyle.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + serviceStyle.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
      </>
          :
          <>
                               <div className="row ">
                      <div className="col-md-3 ">
                        <Link to={"/project-details/Il-Monte-Galala"}>
                          <figure className={styles.service_img}>
                            <img
                              src="./img/Il-Monte-Galala/9.jpg"
                              alt="project"
                              className="w-100"
                            />
                            <div
                              className={
                                styles.loyout +
                                " d-flex justify-content-center align-items-center text-center"
                              }
                            >
                              <div>
                                <h5 className="text-uppercase project_tit  mb-3">Project</h5>
                                <h3 className="my-3 project_desc_layout ">IL Monte Galala</h3>
        
                                <div
                                  className={
                                    styles.icon +
                                    " d-flex justify-content-center align-items-center text-center m-auto"
                                  }
                                >
                                  <i
                                    className={
                                      "fa-solid fa-plus fa-lg " + styles.plus
                                    }
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </figure>
                        </Link>
                      </div>
        
                      <div className="col-md-9 ">
                        <div className="row">
                          <div className="col-md-8">
                            <Link to="/project-details/New-Giza">
                              <figure className={styles.service_img}>
                                <img
                                  src="./img/New-Giza/5.jpg"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    styles.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">
                                      New Giza for Real Estate Development
                                    </h3>
        
                                    <div
                                      className={
                                        styles.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + styles.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
        
                          <div className="col-md-4">
                            <Link to="/project-details/Fouka-Bay">
                              <figure className={styles.service_img}>
                                <img
                                  src="./img/Fouka-Bay/4.jpg"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    styles.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">Fouka Bay</h3>
        
                                    <div
                                      className={
                                        styles.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + styles.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
        
                          <div className="col-md-4">
                            <Link to="/project-details/Porto-New-Cairo-Mall">
                              <figure className={styles.service_img}>
                                <img
                                  src="./img/Porto-New-Cairo-Mall/1.jpg"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    styles.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">Porto New Cairo Mall</h3>
        
                                    <div
                                      className={
                                        styles.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + styles.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
        
                          <div className="col-md-4 ">
                            <Link to="/project-details/Sea-Shell">
                              <figure className={styles.service_img}>
                                <img
                                  src="./img/Sea-Shell/1.jpg"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    styles.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">Seashell</h3>
        
                                    <div
                                      className={
                                        styles.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + styles.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
        
                          <div className="col-md-4 ">
                            <Link to="/project-details/Sodic">
                              <figure className={styles.service_img}>
                                <img
                                  src="./img/Sodic/1.jpg"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    styles.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">Sodic</h3>
        
                                    <div
                                      className={
                                        styles.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + styles.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
        
                    <div className="row ">
                      <div className="col-md-3 ">
                        <Link to="/project-details/New-Giza">
                          <figure className={styles.service_img}>
                            <img
                              src="./img/project/project1.png"
                              alt="project"
                              className="w-100"
                            />
        
                            <div
                              className={
                                styles.loyout +
                                " d-flex justify-content-center align-items-center text-center"
                              }
                            >
                              <div>
                                <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                <h3 className="my-3 project_desc_layout">IL Monte Galala</h3>
        
                                <div
                                  className={
                                    styles.icon +
                                    " d-flex justify-content-center align-items-center text-center m-auto"
                                  }
                                >
                                  <i
                                    className={
                                      "fa-solid fa-plus fa-lg " + styles.plus
                                    }
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </figure>
                        </Link>
                      </div>
        
                      <div className="col-md-9 ">
                        <div className="row">
                          <div className="col-md-8">
                            <Link to="/">
                              <figure className={styles.service_img}>
                                <img
                                  src="./img/project/project2.png"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    styles.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">
                                      New Giza for Real Estate Development
                                    </h3>
        
                                    <div
                                      className={
                                        styles.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + styles.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
        
                          <div className="col-md-4">
                            <Link to="/">
                              <figure className={styles.service_img}>
                                <img
                                  src="./img/project/project3.png"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    styles.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">Fouka Bay</h3>
        
                                    <div
                                      className={
                                        styles.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + styles.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
        
                          <div className="col-md-4">
                            <Link to="/">
                              <figure className={styles.service_img}>
                                <img
                                  src="./img/project/project4.png"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    styles.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">Ein Bay</h3>
        
                                    <div
                                      className={
                                        styles.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + styles.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
        
                          <div className="col-md-4 ">
                            <Link to="/">
                              <figure className={styles.service_img}>
                                <img
                                  src="./img/project/project5.png"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    styles.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">Seashell</h3>
        
                                    <div
                                      className={
                                        styles.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + styles.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
        
                          <div className="col-md-4 ">
                            <Link to="/">
                              <figure className={styles.service_img}>
                                <img
                                  src="./img/project/project6.png"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    styles.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">Fouka Bay</h3>
        
                                    <div
                                      className={
                                        styles.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + styles.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
          </>
          } */}


<div className="row ">
                      <div className="col-md-3 ">
                        <Link to={"/project-details/Il-Monte-Galala"}>
                          <figure className={styles.service_img}>
                            <img
                              src="./img/Il-Monte-Galala/9.jpg"
                              alt="project"
                              className="w-100"
                            />
                            <div
                              className={
                                styles.loyout +
                                " d-flex justify-content-center align-items-center text-center"
                              }
                            >
                              <div>
                                <h5 className="text-uppercase project_tit  mb-3">Project</h5>
                                <h3 className="my-3 project_desc_layout ">IL Monte Galala</h3>
        
                                <div
                                  className={
                                    styles.icon +
                                    " d-flex justify-content-center align-items-center text-center m-auto"
                                  }
                                >
                                  <i
                                    className={
                                      "fa-solid fa-plus fa-lg " + styles.plus
                                    }
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </figure>
                        </Link>
                      </div>
        
                      <div className="col-md-9 ">
                        <div className="row">
                          <div className="col-md-8">
                            <Link to="/project-details/New-Giza">
                              <figure className={styles.service_img}>
                                <img
                                  src="./img/New-Giza/5.jpg"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    styles.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">
                                      New Giza for Real Estate Development
                                    </h3>
        
                                    <div
                                      className={
                                        styles.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + styles.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
        
                          <div className="col-md-4">
                            <Link to="/project-details/Fouka-Bay">
                              <figure className={styles.service_img}>
                                <img
                                  src="./img/Fouka-Bay/4.jpg"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    styles.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">Fouka Bay</h3>
        
                                    <div
                                      className={
                                        styles.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + styles.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
        
                          <div className="col-md-4">
                            <Link to="/project-details/Porto-New-Cairo-Mall">
                              <figure className={styles.service_img}>
                                <img
                                  src="./img/Porto-New-Cairo-Mall/1.jpg"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    styles.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">Porto New Cairo Mall</h3>
        
                                    <div
                                      className={
                                        styles.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + styles.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
        
                          <div className="col-md-4 ">
                            <Link to="/project-details/Sea-Shell">
                              <figure className={styles.service_img}>
                                <img
                                  src="./img/Sea-Shell/1.jpg"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    styles.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">Seashell</h3>
        
                                    <div
                                      className={
                                        styles.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + styles.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
        
                          <div className="col-md-4 ">
                            <Link to="/project-details/Sodic">
                              <figure className={styles.service_img}>
                                <img
                                  src="./img/Sodic/1.jpg"
                                  alt="project"
                                  className="w-100"
                                />
        
                                <div
                                  className={
                                    styles.loyout +
                                    " d-flex justify-content-center align-items-center text-center"
                                  }
                                >
                                  <div>
                                    <h5 className="text-uppercase project_tit mb-3">Project</h5>
                                    <h3 className="my-3 project_desc_layout">Sodic</h3>
        
                                    <div
                                      className={
                                        styles.icon +
                                        " d-flex justify-content-center align-items-center text-center m-auto"
                                      }
                                    >
                                      <i
                                        className={
                                          "fa-solid fa-plus fa-lg " + styles.plus
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
        </div>
      </section>
    </>
  );
}
